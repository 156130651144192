import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { GoogleAuthApi } from '../redux/apis/GoogleAuthApi';
import { login } from '../redux/slices/GoogleAuthSlice';

export const GoogleDomainLogin = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error, refetch: googleAuthRefetch } = GoogleAuthApi.useGoogleDomainAuthQuery({ enabled: false, });

  const handleLoginSuccess = (response: any) => {
    console.log("*************************************************response****************************************************");
    console.log(response);
    googleAuthRefetch();

    if (data) {
      dispatch(login(data));
    }

    // axios.post(`${process.env.REACT_APP_NODE_SERVER_HOST}/auth/google`, {}, {
    //   headers: {
    //     'authorization': response.credential,
    //     'MyCustomHeader2': '2'
    //   }
    // })
    //   .then((res) => console.log(res.data))
    //   .catch((err) => console.error(err));
  }

  const handleLoginFailure = () => {
    console.log("Error Occured!");
  }

  return (
    <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
  );
}
import { GoogleDomainLogin } from '../components/GoogleDomainLogin';

export const LoginPage = () => {

    return (
        <div>
            <h1>VVM CBSE School</h1>
            <h2>Edusys Login:</h2>
            <GoogleDomainLogin />
        </div>
    );
}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const GoogleAuthApi = createApi({
    reducerPath: 'googleAuthApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_NODE_SERVER_HOST}/auth/google`
    }),
    endpoints: (builder) => ({
        googleDomainAuth: builder.query({
            query: () => '/'
        }),
    }),
});
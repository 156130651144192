import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { GoogleAuthApi } from './apis/GoogleAuthApi';

const storeReducers = combineReducers({
  [GoogleAuthApi.reducerPath]: GoogleAuthApi.reducer,
});

const storeMiddleware = (getDefaultMiddleware: any) => getDefaultMiddleware()
  .concat(GoogleAuthApi.middleware);

export const store = configureStore({
  reducer: storeReducers,
  middleware: storeMiddleware
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch